import { useState } from "react";
import Image from "next/image";
import styles from "./choose.module.css";
import icon1 from "../../assets/images/home/newicon/icon-1.png";
import icon2 from "../../assets/images/home/newicon/icon-2.png";
import icon3 from "../../assets/images/home/newicon/icon-3.png";
import icon4 from "../../assets/images/home/newicon/icon-4.png";
import icon5 from "../../assets/images/home/newicon/icon-5.png";
import icon6 from "../../assets/images/home/newicon/icon-6.png";
import icon7 from "../../assets/images/home/newicon/icon-7.png";
import icon8 from "../../assets/images/home/newicon/icon-8.png";

const WhyChooseUs = () => {
  const items = [
    { title: "Top-Notch Results", content: "This is where Shankar IAS Academy comes into the picture, as we have produced more than 2,200 successful IAS, IPS, IFS and other elite service officers to date. ", content1:"Our results are the best evidence of the effectiveness of our best in class coaching methodology, and for this reason, we consider ourselves the best academy for UPSC aspirants.", icon: icon1 },
    { title: "Student-Achiever Interaction", content: "Our students have frequent interactions with 130+ successful and serving IAS, IPS, and IFS officers. ",content1:"It is a rich source of knowledge, experience and encouragement from those who have been there and done it in the UPSC process.", icon: icon2 },
    { title: "Extensive Test Series", content: "We provide a complete test series to help the students to get ready for all the stages of the UPSC exam. Our structured test series includes: ",content1:" Prelims: 25 offline and 30 online tests Mains: 10 offline tests marked by UPSC like parameters.", icon: icon3 },
    { title: "Free Interview Guidance", content: "It's essential to crack the UPSC interview (Personality Test) for final selection. Shankar IAS Academy offers comprehensive interview training, involving mock interviews by seasoned panelists.", content1:"This helps aspirants gain the confidence and expression required to master their personality tests.", icon: icon4 },
    { title: "Online Classes – First in India", content: "Shankar IAS Academy pioneered the use of an interactive online UPSC course in India. ", content1:"Our two-way live communication system allows students from all over India to communicate with faculty members in real time, providing a class-room experience from the safety of their homes.", icon: icon5 },
    { title: "Mentorship Programme", content: " At Shankar IAS Academy, we are convinced that individualized guidance is the key to success.",content1:" Our 360° mentorship programme provides ongoing support and expert guidance to assist students in forming their opinions, analytical abilities, and overall strategy towards the exam.", icon: icon6 },
    { title: "World-Class Infrastructure", content: "Our learning centers have world-class infrastructure that gives two-way interactive learning. ",content1:"Our state-of-the-art learning centers have the best equipment and amenities available, giving our students a two-way learning process that leaves them with the most advanced facilities and resources available worldwide.", icon: icon7 },
    { title: "Home Away From Home", content: "For outstation candidates, we provide guidance in choosing from more than 40 hostels so that they can stay comfortably and prepare for the UPSC exams. ",content1:"This support makes us the best academy for UPSC and other competitive exams, attracting students from across the country.", icon: icon8 }
  ];
  
  return (
     
    <div className="container-fluid pb-2">
     
      <div className="row">
        <div className={`${styles.roadmapFirst} p-4 col-md-12 `} id="roadmapFirst">
          <div className="col">
            <div className="row">
            <h1 className={`${styles.sectiontitle} text-center mb-0 pb-2`}>
              Why choose Shankar IAS Academy among others?
            </h1>
              {items.map((item, index) => (
                <HoverBox3D key={index} title={item.title} content={item.content} content1={item.content1} icon={item.icon} />
              ))}


            </div>
          </div>
        </div>
      </div>
     
    </div>
    
  );
};

const HoverBox3D = ({ title, content, content1, icon }) => {

  return (

    <div className="col-md-3 pe-0 position-relative">
       <style jsx>{`
       .card {
            background: linear-gradient(180deg, #ffffff 0%, #f8f9fa 100%);          
            box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.2);
            border-radius: 10px;
            padding: 10px;
            overflow: hidden;
            margin-top: 20px;
            height: 230px;
            position: relative;
            text-overflow: ellipsis;
            transition: box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out;
            
        }
             @media (min-width: 768px) {
        .card:hover {
            box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
            height: auto;
            position: absolute;
            top: 0;
            z-index: 10;
            transform: translateY(5px);
            cursor: pointer;
             
        }
            .card:hover .hide{
              display: none;
            }
            
            .card .viewcontent {
               display: none;
            }

            .card h5 {
            font-size: 18px;
            margin-bottom: 10px;
            color: #0d436c;
           }
            
          .card:hover .viewcontent {
            display: block;
            margin-top: 10px;
          }

        .card p
        {
            padding: 0 17px;
        }
        .card .viewcontent
        {
          display: none;
          }
      .card:hover .viewcontent{
            display: block;
            margin-top:10px;
        }

  .card .standard {
    font-size: 16px;
    padding: 0 17px;
    line-height: 22px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical; 
    
    
  }
 .card:hover .standard{
    -webkit-line-clamp: unset; /* Remove line clamp for expanded view */
      overflow: unset;
  }
       }

        /* Mobile styles - Always show content without hover */
    @media (max-width: 767px) {
      .card {
        height: auto; /* Ensure the card height expands naturally */
        box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.2); /* Keep box-shadow normal on mobile */
        transform: none; /* No hover transform effect */
      }

      /* Show content on mobile directly without hovering */
      .card .viewcontent {
        display: block;
        margin-top: 10px;
      }

      .card .standard {
        display: block; /* Show all content */
        -webkit-line-clamp: unset; /* Remove line-clamp so all text is shown */
        overflow: unset;
      }

      /* Ensure the "Readmore" text is hidden on mobile (it’s not necessary on mobile) */
      .card .hide {
        display: none;
      }

      /* Remove hover effect styles */
      .card:hover {
        box-shadow: none; /* No hover box-shadow effect on mobile */
        transform: none; /* No hover transform effect on mobile */
      }

       .card h5 {
            font-size: 18px;
            margin-bottom: 10px;
            color: #0d436c;
           }
    }


      `}</style>
      <div className="card">
     
        
        <div className="text-center pt-2">
       <Image src={icon} width="55" height="55" alt="icon" className="iconimg" />
       </div>
       <h5 className="text-center pt-2">{title}</h5>
       <div className="standard"> {content}
       

       </div>
      
       <div className="col text-center hide mt-4">
        <span className="text-xs" style={{ color: '#df1d1d' }}>Readmore</span>
        
        </div>
         <p className="viewcontent">{content1}</p>

     
      
       
      
     </div>


    </div>
 
  );
};

export default WhyChooseUs;
